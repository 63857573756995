import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OrderStatus,
  QPilotAccessToken,
  QPilotActionTriggerTypes,
  QPilotCalculateTotalsRequest,
  QPilotCoupon,
  QPilotOfferStatusTypes,
  QPilotPaymentMethod,
  QPilotProduct,
  QPilotScheduledOrder,
  QPilotScheduledOrderItem,
  QPilotScheduledOrderTotals,
  QPilotSiteSettings,
  QPilotSurvey,
  QPilotSurveyAnswer,
} from '@qpilot/subscriber-portal-web-component';
import { Observable, share } from 'rxjs';

import { environment } from '../../environments/environment';
import { RemappedOmit } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class QPilotCloudApiService {

  private qpilotUrl = environment.apiUrl;
  private qpilotNonCoreProcessesUrl = environment.apiNonCoreUrl;
  private accessToken: QPilotAccessToken;
  private siteId: number;
  private customerId: number;

  constructor(private httpClient: HttpClient) { }

  setAccessToken(token: string) {
    this.accessToken = new QPilotAccessToken();
    this.accessToken.TokenBearerAuth = token;
  }

  setSiteId(siteId: number) {
    this.siteId = siteId;
  }

  setCustomerId(customerId: number) {
    this.customerId = customerId;
  }

  getSiteSettings(): Observable<QPilotSiteSettings & { nextimeSiteId?: string }> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/CustomerPortalSettings`;
    return this.httpGet(endpoint, this.accessToken);
  }

  getCustomerByExternalId(): Observable<any> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/Customers/${this.customerId}`;
    return this.httpGet(endpoint, this.accessToken);
  }

  calculateScheduledOrderTotals(request: QPilotCalculateTotalsRequest): Observable<QPilotScheduledOrderTotals> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/CalculateTotals`;
    return this.httpPost<QPilotScheduledOrderTotals>(endpoint, request, this.accessToken);
  }

  getScheduledOrdersForCustomer(): Observable<QPilotScheduledOrder[]> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/Customers/${this.customerId}/ScheduledOrders`;
    return this.httpGet<QPilotScheduledOrder[]>(endpoint, this.accessToken);
  }

  getPaymentMethodsForCustomer(): Observable<QPilotPaymentMethod[]> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/Customers/${this.customerId}/PaymentMethods`;
    return this.httpGet<QPilotPaymentMethod[]>(endpoint, this.accessToken);
  }

  getScheduledOrderById(orderId: number): Observable<QPilotScheduledOrder & Record<string, any>> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/${orderId}`;
    return this.httpGet<QPilotScheduledOrder>(endpoint, this.accessToken);
  }

  updateScheduledOrder(order: QPilotScheduledOrder & Record<string, any>): Observable<QPilotScheduledOrder> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/${order.id}`;
    return this.httpPut<QPilotScheduledOrder>(endpoint, { ...order }, this.accessToken);
  }

  safeActivateScheduledOrder(orderId: number) {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/${orderId}/SafeActivate?allowDeleted=true`;
    return this.httpPut(endpoint, "", this.accessToken);
  }

  getCouponByCode(scheduledOrderId: number, couponCode: string): Observable<QPilotCoupon> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/Coupons/ScheduledOrders/${scheduledOrderId}?code=${couponCode}`;
    return this.httpGet(endpoint, this.accessToken);
  }

  changeScheduledOrderStatus(
    orderId: number,
    updatedStatus: OrderStatus,
  ) {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/${orderId}/Status/${updatedStatus}`;
    return this.httpPut(endpoint, "", this.accessToken);
  }

  deleteScheduledOrder(scheduledOrderId: number) {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/${scheduledOrderId}`;
    return this.httpDelete(endpoint, this.accessToken);
  }

  createScheduledOrderItem(
    orderItem: RemappedOmit<QPilotScheduledOrderItem, 'id'>
  ): Observable<QPilotScheduledOrderItem[]> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrderItems`;
    return this.httpPost(
      endpoint,
      {
        scheduledOrderId: orderItem.scheduledOrderId,
        scheduledOrderItems: [orderItem]
      },
      this.accessToken
    );
  }

  updateScheduledOrderFrequency(
    orderId: number,
    frequency: number,
    frequencyType: string
  ): Observable<QPilotScheduledOrder> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/${orderId}/Frequency`;
    return this.httpPut(endpoint, { frequency, frequencyType }, this.accessToken);
  }

  updateScheduledOrderPaymentMethod(
    orderId: number,
    paymentMethodId: number
  ): Observable<QPilotScheduledOrder> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/${orderId}/PaymentMethod`;
    return this.httpPatch(endpoint, { paymentMethodId }, this.accessToken);
  }

  updateScheduledOrderNextOccurrence(
    orderId: number,
    nextOccurrenceUtc: Date,
  ): Observable<QPilotScheduledOrder> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/${orderId}/NextOccurrenceUtc`;
    return this.httpPut(endpoint, { nextOccurrenceUtc }, this.accessToken);
  }

  updateScheduledOrderDeliveryInfo(
    orderId: number,
    estimatedDeliveryDate: string,
    nextOccurenceDate: string,
    shippingLine: {
      name: string,
      total: number,
      shippingMethod: string,
    }
  ): Observable<QPilotScheduledOrder> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/${orderId}/EstimatedDeliveryDate`;
    const body = {
      estimatedDeliveryDate: estimatedDeliveryDate,
      nextOccurenceDate: nextOccurenceDate,
      shippingLine: {
        name: shippingLine.name,
        total: shippingLine.total,
        shippingMethod: shippingLine.shippingMethod,
      }
    }
    return this.httpPut<QPilotScheduledOrder>(endpoint, body, this.accessToken);
  }

  getNextOccurrenceUtc(
    { frequency, frequencyType }: QPilotScheduledOrder,
    fromUtc?: string,
  ): Observable<Date> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/NextOccurrenceUtc`;
    let params = new HttpParams()
      .set('frequency', frequency)
      .set('frequencyType', frequencyType);
    if (fromUtc) params = params.append('fromUtc', fromUtc);
    return this.httpGet(endpoint, this.accessToken, params);
  }

  updateScheduledOrderItem(
    orderItem: QPilotScheduledOrderItem
  ): Observable<QPilotScheduledOrderItem> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrderItems/${orderItem.id}`;
    return this.httpPut(endpoint, orderItem, this.accessToken);
  }

  deleteScheduledOrderItem(orderItemId: number) {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrderItems/${orderItemId}`;
    return this.httpDelete(endpoint, this.accessToken);
  }

  getProducts(search?: string) {
    let endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/Products`;
    const params = new URLSearchParams({ active: "true", valid: "true", addToScheduledOrder: "true" });
    if (search && search?.trim() !== '') {
      params.append("search", search);
    }
    endpoint = `${endpoint}?${params.toString()}`;
    return this.httpGet<{ items: QPilotProduct[]}>(endpoint, this.accessToken);
  }

  retryProcessing(orderId: number): Observable<QPilotScheduledOrder> {
    const endpoint = `${this.qpilotUrl}/Sites/${this.siteId}/ScheduledOrders/${orderId}/Retry`;
    return this.httpPost(endpoint, "", this.accessToken);
  }

  getSurveys(
    actionTriggerType: QPilotActionTriggerTypes
  ): Observable<{ result: { surveys: { survey: QPilotSurvey }[] } }> {
    const endpoint =
      `${this.qpilotNonCoreProcessesUrl}/v1/Survey/${this.siteId}/${this.customerId}/offer`;
    const params = new HttpParams().set('ActionTrigger', actionTriggerType.toString())
    return this.httpGet(endpoint, this.accessToken, params);
  }

  answerSurvey(
    orderId: number,
    surveyId: number,
    answer: QPilotSurveyAnswer,
    statusType: QPilotOfferStatusTypes,
    comment: string | undefined,
  ) {
    const endpoint =
      `${this.qpilotNonCoreProcessesUrl}/v1/Survey/${this.siteId}/${this.customerId}/${surveyId}`;
    const body = {
      scheduledOrderId: orderId,
      questionId: answer.id,
      customerId: this.customerId,
      answer: statusType,
      comment,
    }
    return this.httpPut(endpoint, body, this.accessToken);
  }

  private httpGet<TOut>(
    url: string,
    accessToken: QPilotAccessToken,
    params?: HttpParams
  ): Observable<TOut> {
    const headers = this.createHeaders(accessToken);
    return this.httpClient.get<TOut>(url, { headers, params })
      .pipe(
        share({ resetOnError: false, resetOnComplete: false, resetOnRefCountZero: false }
      ));
  }

  private httpPost<TOut>(url: string, body: any, accessToken: QPilotAccessToken): Observable<TOut> {
    const headers = this.createHeaders(accessToken);
    return this.httpClient.post<TOut>(url, body, { headers: headers })
      .pipe(
        share({ resetOnError: false, resetOnComplete: false, resetOnRefCountZero: false }
      ));
  }

  private httpPut<TOut>(url: string, body: any, accessToken: QPilotAccessToken): Observable<TOut> {
    const headers = this.createHeaders(accessToken);
    return this.httpClient.put<TOut>(url, body, { headers: headers })
      .pipe(
        share({ resetOnError: false, resetOnComplete: false, resetOnRefCountZero: false }
      ));
  }

  private httpPatch<TOut>(url: string, body: any, accessToken: QPilotAccessToken): Observable<TOut> {
    const headers = this.createHeaders(accessToken);
    return this.httpClient.patch<TOut>(url, body, { headers: headers })
      .pipe(
        share({ resetOnError: false, resetOnComplete: false, resetOnRefCountZero: false }
      ));
  }

  private httpDelete<TOut>(url: string, accessToken: QPilotAccessToken): Observable<TOut> {
    const headers = this.createHeaders(accessToken);
    return this.httpClient.delete<TOut>(url, { headers: headers })
      .pipe(
        share({ resetOnError: false, resetOnComplete: false, resetOnRefCountZero: false }
      ));
  }

  private createHeaders(accessToken: QPilotAccessToken): HttpHeaders {
    const headers = new HttpHeaders();
    if (accessToken?.TokenBearerAuth)
      return headers.append('Authorization', 'Bearer ' + accessToken.TokenBearerAuth);
    if (accessToken?.TokenAuth)
      return headers.append('Authorization', 'Basic ' + accessToken.TokenAuth);

    return headers;
  }
}
