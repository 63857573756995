import '@material/web/button/text-button';
import '@material/web/icon/icon';
import '@material/web/iconbutton/icon-button';
import '@material/web/textfield/outlined-text-field';

import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { QPilotProduct } from '@qpilot/subscriber-portal-web-component';
import { map, Observable, tap } from 'rxjs';

import { DataService } from '../services/data.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { SiteSettingsService } from '../services/site-settings.service';

@Component({
  selector: 'app-add-product-list',
  standalone: true,
  imports: [
    MatDialogModule,
    AsyncPipe,
    FormsModule,
    NgStyle,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './add-product-list.component.html',
  styleUrl: './add-product-list.component.scss'
})
export class AddProductListComponent {
  products$: Observable<QPilotProduct[]>;
  searchTerm = '';

  constructor(
    private qPilotCloudApiService: QPilotCloudApiService,
    protected siteSettingsService: SiteSettingsService,
    private dataService: DataService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit() {
    this.products$ = this.dataService.products$;
  }

  search() {
    this.products$ = this.qPilotCloudApiService
                         .getProducts(this.searchTerm)
                         .pipe(
                            map(data => data.items),
                            tap((data) => this.dataService.setProducts(data)));
  }

  onSearchInput(event: Event) {
    this.searchTerm = (event.target as any)?.['value'];
  }

  operateProduct(product: QPilotProduct) {
    this.router.navigate(
      [
        { outlets: { dialog: ['add-products', product.id] } }
      ],
      { skipLocationChange: true },
    );
  }

  goBack() {
    this.dialog.closeAll();
  }

  getItemBackgroundImage(product: QPilotProduct) {
    return {
      backgroundImage: `url('${
        product.imageUrl
        ?? product?.metadata?.imageUrl
        ?? product?.metadata?.imageThumbUrl
        ?? ''
      }')`,
    }
  }
}
